




// Core
import { Component, Vue } from 'vue-property-decorator'

// Stores
import ReportsModule from '@/store/modules/reports'

@Component
export default class ReportLayout extends Vue {
  private breadcrumbs = [{
    name: 'Отчеты',
    value: 'reports',
  }]

  private created () {
    ReportsModule.fetchFields()
  }
}
